import {Box} from '@mui/material';
import React, {useMemo} from 'react';
import {IHorizontalScrollingContainerMediaGallery} from '@wearesection/bandai-react-components/organisms/HorizontalScrollingContainerMediaGallery';
import {ICardGame} from '@wearesection/bandai-react-components/organisms/HorizontalScrollingContainerMediaGallery/CardGame';

import * as Types from '../../../../graphql/types';
import {PLACEHOLDER_IMG} from '~/constants';
import RenderNextImage from '../../atoms/render-next-image';
import {GetRouter} from '~/utils';
import dynamic from 'next/dynamic';
const HorizontalScrollingContainerMediaGallery = dynamic(
  () =>
    import(
      '@wearesection/bandai-react-components/organisms/HorizontalScrollingContainerMediaGallery'
    ),
  {
    ssr: false,
  }
);
const PADDING_COMPONENT: any = {
  '/': {
    paddingTop: '40px',
    paddingBottom: '40px',
    h2: {
      'margin-bottom': '40px !important',
    },
  },
  '/games': {
    paddingTop: {
      xs: '0px',
      lg: '0px',
    },
    paddingBottom: {
      xs: '72px',
      lg: '40px',
    },
  },
  '/games/[slug]': {
    display: 'flex',
    paddingBottom: {
      xs: '80px',
      lg: '40px',
    },
  },
};

const adapterGamesListing = (
  data: Types.GamesListing & any
): IHorizontalScrollingContainerMediaGallery => {
  const valueMediaGallery = data.listItemCollection?.items;

  let listGameCard: ICardGame[] = [];
  const {locale} = GetRouter();
  if (valueMediaGallery?.length) {
    listGameCard = valueMediaGallery.map((card: any) => {
      return {
        title: card?.title || '',
        href: card?.slug ? `/${locale}/games/${card?.slug}` : undefined,
        image:
          card?.carouselKeyartGameThumbnailImage?.media?.url ||
          card?.mastheadBackgroundImage?.media?.url ||
          PLACEHOLDER_IMG,
        mainImageNext: (
          <RenderNextImage
            layout="fill"
            src={
              card?.carouselKeyartGameThumbnailImage?.media?.url ||
              card?.mastheadBackgroundImage?.media?.url ||
              PLACEHOLDER_IMG
            }
            width={800}
            height={450}
            alt={card?.title || ''}
          />
        ),
        nextImage: (
          <RenderNextImage
            layout="fill"
            src={
              card?.carouselKeyartGameThumbnailImage?.media?.url ||
              card?.mastheadBackgroundImage?.media?.url ||
              PLACEHOLDER_IMG
            }
            width={358}
            height={209}
            alt={card?.title || ''}
          />
        ),
      };
    });
  }
  const newData: IHorizontalScrollingContainerMediaGallery = {
    title: data?.title || '',
    hrefSeeAll: `/${locale}/games`,
    titleSeeAll: data?.description || '',
    listGame: listGameCard,
    isHomePage: true,
  };

  return newData;
};

const adapter = (data: Types.MediaGallery): IHorizontalScrollingContainerMediaGallery => {
  const valueMediaGallery = data?.screenshotsCollection?.items;
  let listGameCard: ICardGame[] = [];
  if (valueMediaGallery?.length) {
    listGameCard = valueMediaGallery.map(card => {
      return {
        title: '',
        image: card?.media?.url || PLACEHOLDER_IMG,
        mainImageNext: (
          <RenderNextImage
            layout="fill"
            src={card?.media?.url || PLACEHOLDER_IMG}
            width={800}
            height={450}
            alt={card?.media?.title || ''}
          />
        ),
        nextImage: (
          <RenderNextImage
            layout="fill"
            src={card?.media?.url || PLACEHOLDER_IMG}
            width={358}
            height={209}
            alt={card?.media?.title || ''}
          />
        ),
      };
    });
  }
  const newData: IHorizontalScrollingContainerMediaGallery = {
    title: data?.title || '',
    listGame: listGameCard,
  };
  return newData;
};

const handleTypeName = (props: any) => {
  const typeName: any = {
    HorizontalScrollingContainerMediaGallery: adapter(props),
    GamesListing: adapterGamesListing(props),
  };
  return typeName[props.__typename] || adapter(props);
};

const HorizontalScrollingContainerMediaGalleryOrganisms: React.FC<Types.GamesListing> = (
  props: any
) => {
  // const ComponentProps = useMemo(() => handleTypeName(props), [props]);
  const ComponentProps = handleTypeName(props);

  const {pathname} = GetRouter();

  return (
    <Box sx={PADDING_COMPONENT[pathname] ?? PADDING_COMPONENT['/']}>
      <HorizontalScrollingContainerMediaGallery {...ComponentProps} id={props?.id || ''} />
    </Box>
  );
};

export default HorizontalScrollingContainerMediaGalleryOrganisms;
